import React from "react";
import { Box, Typography, Card, CardContent, Button } from "@mui/material";
import { motion } from "framer-motion"; // Framer Motion'u içe aktar
import { useLanguage } from "../../components/languageprovider";
import language from "../../constants/language";

const RegisterSuccess = ({ user, setUser }) => {
  const { currentLanguage } = useLanguage();
  const texts = language[currentLanguage];

  // Animasyon ayarları
  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 }, // Başlangıç durumu
    visible: { opacity: 1, y: 0 }, // Görünür durum
  };
  const handleRedirect = () => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /android/i.test(navigator.userAgent);

    if (isIOS) {
      // Uygulamayı açmayı dener
      setTimeout(() => {
        // 2 saniye sonra App Store'a yönlendir
        window.location.href =
          "https://apps.apple.com/tr/app/a-flying/id6702013325";
      }, 2000);
    } else if (isAndroid) {
      // Uygulamayı açmayı dener
      window.location.href = "aflying://";
      setTimeout(() => {
        // 2 saniye sonra Play Store'a yönlendir
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.example.aflying";
      }, 2000);
    } else {
      // Diğer platformlar için varsayılan yönlendirme
      window.open("https://a-flying.com/", "_blank");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        height: "100vh",
        padding: 2,
        backgroundColor: "#fff", // Beyaz arka plan
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          backgroundColor: "#f0f4f8", // Kart arka plan rengi
          boxShadow: 3,
          borderRadius: 2,
          padding: 3,
        }}
      >
        <CardContent>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: 1 }} // 1 saniyelik animasyon
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              {texts.registerSuccess}
            </Typography>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: 1, delay: 0.5 }} // 0.5 saniye gecikmeli
          >
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{ mb: 2 }}
            >
              {texts.registerSuccessDescription}
            </Typography>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: 1, delay: 1 }} // 1 saniye gecikmeli
          >
            <Typography
              variant="h6"
              color="success.main"
              align="center"
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              {texts.registrationConfirmed}
            </Typography>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={fadeInVariants}
            transition={{ duration: 1, delay: 1.5 }} // 1.5 saniye gecikmeli
          >
            <Typography
              variant="body1"
              color="textSecondary"
              align="center"
              sx={{ mb: 2 }}
            >
              {texts.returnToApp}
            </Typography>
          </motion.div>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              handleRedirect();
            }}
          >
            {texts.goToApp} {/* Buton metni */}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RegisterSuccess;
