import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { countryOptions } from "../../constants/countries";
import Select from "react-select";
import { useLanguage } from "../../components/languageprovider";
import language from "../../constants/language";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
};

const RegisterStepThree = ({ user, setUser }) => {
  const { currentLanguage } = useLanguage();
  const texts = language[currentLanguage];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <h2>{texts.userCountry}</h2>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {texts.postalCodeInfo} {/* Posta kodu hakkında bilgi */}
      </Typography>
      <TextField
        size="small"
        label={texts.userZipCode}
        onChange={(e) => {
          const value = e.target.value;
          // Girdi 5 hane ile sınırlı olacak
          if (value.length <= 5) {
            setUser({ ...user, userPostalCode: value });
          }
        }}
        value={user.userPostalCode || ""}
        fullWidth
        type="number"
      />

      <Typography variant="body2" color="textSecondary" gutterBottom>
        {texts.countryInfo} {/* Ülke seçimi hakkında bilgi */}
      </Typography>
      <Select
        options={countryOptions}
        styles={customStyles}
        value={countryOptions.find(
          (option) => option.value === user.userCountry
        )}
        onChange={(e) => setUser({ ...user, userCountry: e.value })}
        isSearchable
        className="font-[Inter] w-full"
      />
    </Box>
  );
};

export default RegisterStepThree;
