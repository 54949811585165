import React, { useState, useEffect } from "react";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useLanguage } from "../../components/languageprovider";
import { useNavigate, useParams } from "react-router-dom";
import {
  CardContent,
  Card,
  Button,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { toast } from "react-toastify";
import language from "../../constants/language";
import { motion } from "framer-motion";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const texts = language[currentLanguage];
  const [loading, setLoading] = useState(false);
  const [printPrices, setPrintPrices] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  const { productId } = useParams();

  const countries = [
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
  ];

  const getAllPrintPrices = async () => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/getAllPrint?page=0&size=9999999&sort=printPrice,asc`
      );
      const data = await response.json();
      setPrintPrices(data?.content || []);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getProductById = async (productId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ProductSubCategoryRelation/findProductSubcategoryRelationByUserIdWithUserInfo?productId=${productId}`
      );
      const data = await response.json();
      setProduct(data?.content[0]?.product);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      getAllPrintPrices();
      changeLanguage("de");
      getProductById(productId);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  const handlePriceSelect = (event) => {
    const selectedId = event.target.value;
    const selected = printPrices.find((price) => price.printId === selectedId);
    setSelectedPrice(selected);
  };

  const handleSavePrintRequest = async () => {
    const data = {
      user: {
        userId: product?.user?.userId,
      },
      product: {
        productId: product?.productId,
      },
      print: {
        printId: selectedPrice?.printId,
      },
      requestDate: new Date().toISOString(),
      requestStatus: true,
      requestDescription:
        product?.user?.userName +
        " " +
        product?.user?.userSurname +
        " hat eine Anzeige für den Druck " +
        selectedPrice?.printNameDe +
        " erstellt.",
    };

    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/printRequest/savePrintRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success(texts.saveSuccess);
        navigate("/");
      }
    } catch (error) {
      toast.error(texts.saveError);
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <div
          className="min-h-screen grid grid-rows-[1fr_auto] bg-gray-100"
          style={{ padding: "20px", boxSizing: "border-box" }}
        >
          <Card
            className="shadow-2xl p-6 mx-auto"
            style={{
              maxWidth: "800px",
              backgroundColor: "#ffffff",
              borderRadius: "16px",
            }}
          >
            <motion.div
              className="flex flex-wrap gap-4 items-center justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              {countries.map((country, index) => (
                <button
                  key={index}
                  onClick={() => {
                    changeLanguage(country.id);
                  }}
                >
                  <img
                    className="w-16 rounded-lg cursor-pointer hover:scale-110 transition ease 1s"
                    src={country.flags.svg}
                    alt={country.name}
                  />
                </button>
              ))}
            </motion.div>
            <h2
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "24px",
                margin: "20px",
              }}
            >
              {texts.selectPackage}
            </h2>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {product ? (
                <div className="w-full flex flex-col items-center gap-4">
                  {/* Ürüne ait resim */}
                  <img
                    src={
                      API_URL +
                        ":" +
                        API_PORT +
                        "/" +
                        product?.productImagePath ||
                      "https://placehold.co/105x150/orange/white"
                    }
                    alt={product.productName || "Product Image"}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "12px",
                      maxWidth: "350px",
                      objectFit: "cover",
                      boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                    }}
                    className="responsive-img"
                  />

                  {/* Ürünün adı */}
                  <Typography
                    variant="h6"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {product?.user?.userName +
                      " " +
                      product?.user?.userSurname || "User"}
                  </Typography>
                </div>
              ) : (
                <Typography style={{ textAlign: "center", color: "#ff5252" }}>
                  {texts.noProductFound}
                </Typography>
              )}
            </CardContent>

            {/* Print Prices Seçenekleri */}
            <CardContent>
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "16px",
                }}
              >
                {texts.selectPrintPrice}
              </h3>
              {printPrices.length > 0 ? (
                <RadioGroup
                  aria-label="printPrices"
                  value={selectedPrice?.printId || ""}
                  onChange={(event) => handlePriceSelect(event)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  {printPrices.map((price, index) => (
                    <FormControlLabel
                      key={index}
                      value={price.printId}
                      control={<Radio style={{ color: "#007BFF" }} />}
                      label={`Price: ${price.printNameDe} - ${price.printPrice} €`}
                      style={{
                        backgroundColor: "#f9f9f9",
                        padding: "10px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
                      }}
                    />
                  ))}
                </RadioGroup>
              ) : (
                <Typography style={{ textAlign: "center", color: "#ff5252" }}>
                  {texts.noPrintPrices}
                </Typography>
              )}
            </CardContent>

            {/* Seçilen fiyatın gösterilmesi */}
            {selectedPrice && (
              <CardContent style={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  style={{ fontSize: "16px", marginBottom: "12px" }}
                >
                  {texts.selectedPrice}: {selectedPrice.printNameDe} -{" "}
                  {selectedPrice.printPrice} €
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#007BFF",
                    padding: "10px 20px",
                    fontSize: "16px",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    selectedPrice && product && handleSavePrintRequest()
                  }
                >
                  {texts.confirmSelection}
                </Button>
              </CardContent>
            )}
          </Card>
        </div>
      )}
    </>
  );
};

export default Index;
