// RegisterStepThree.js
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ibanData from "./ibandata.js";

const RegisterStepFour = ({
  userBankAccount,
  setUserBankAccount,
  texts,
  user,
  ibanError,
  setIbanError,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("bank"); // default olarak banka seçiliyor
  let countryCode = user.userCountry;

  const handlePaymentMethodChange = (event) => {
    setUserBankAccount({});
    setPaymentMethod(event.target.value);
  };
  useEffect(() => {
    if (userBankAccount.iban) {
      setPaymentMethod("bank");
    } else if (userBankAccount.paypalNumber) {
      setPaymentMethod("paypal");
    }
  }, [userBankAccount]);

  const validateIBAN = (iban, countryCode) => {
    const countryIban = ibanData[countryCode.toLowerCase()]; // Küçük harf kodlar
    if (!countryIban) {
      return `${texts.ibanunsupported} ${countryCode.toUpperCase()}`;
    }

    // Ülke kodu kontrolü (ilk iki hane ülke kodu olmalı)
    if (countryCode === "en") {
      countryCode = "gb";
    }
    if (iban.slice(0, 2).toUpperCase() !== countryCode.toUpperCase()) {
      return `${texts.ibanInvalidStart} ${countryCode.toUpperCase()}.`;
    }

    // IBAN uzunluk kontrolü
    if (iban.length !== countryIban.length) {
      return `${texts.ibanMustBe} ${countryIban.length} ${
        texts.ibanInvalidLength
      } ${countryCode.toUpperCase()}.`;
    }

    // IBAN regex kontrolü
    if (!countryIban.regex.test(iban)) {
      return `${texts.invalidIbanFormat} ${countryCode.toUpperCase()}.`;
    }

    return "";
  };

  useEffect(() => {
    if (!userBankAccount.iban) {
      setIbanError("");
    }
  }, [userBankAccount.iban]);

  const handleIBANChange = (e) => {
    const iban = e.target.value.toUpperCase(); // IBAN harfleri büyük olmalı
    const errorMessage = validateIBAN(iban, countryCode);

    setUserBankAccount({ ...userBankAccount, iban });
    setIbanError(errorMessage);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <h2>{texts.bankInformationDescription}</h2>

      {/* Banka veya PayPal seçim kısmı */}
      <FormControl component="fieldset">
        <FormLabel component="legend">{texts.selectPaymentMethod}</FormLabel>
        <RadioGroup
          row
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
        >
          <FormControlLabel
            value="bank"
            control={<Radio />}
            label={
              <Box display="flex" alignItems="center">
                <img
                  src={
                    "https://cdn-icons-png.flaticon.com/512/3767/3767210.png"
                  }
                  alt="Bank Logo"
                  style={{ width: 20, marginRight: 8 }}
                />
                {texts.bankInformation}
              </Box>
            }
          />
          <FormControlLabel
            value="paypal"
            control={<Radio />}
            label={
              <Box display="flex" alignItems="center">
                <img
                  src={
                    "https://cdn-icons-png.flaticon.com/512/2504/2504802.png"
                  }
                  alt="PayPal Logo"
                  style={{ width: 20, marginRight: 8 }}
                />
                {texts.paypal} {/* PayPal metni için yeni ekleme */}
              </Box>
            }
          />
        </RadioGroup>
      </FormControl>

      {/* Banka bilgileri formu */}
      {paymentMethod === "bank" && (
        <>
          <p>{texts.bankDetailsDescription}</p>{" "}
          {/* Banka bilgilerini almak için açıklama */}
          <TextField
            size="small"
            label={texts.userIBAN}
            onChange={handleIBANChange}
            value={userBankAccount.iban || ""}
            fullWidth
            error={Boolean(ibanError)}
            helperText={ibanError || ""}
          />
          <TextField
            size="small"
            label={texts.userBankAccountOwnerName}
            onChange={(e) =>
              setUserBankAccount({
                ...userBankAccount,
                bankAccountUserName: e.target.value,
              })
            }
            value={userBankAccount.bankAccountUserName || ""}
            fullWidth
          />
          <TextField
            size="small"
            label={texts.userBankAccountOwnerSurname}
            onChange={(e) =>
              setUserBankAccount({
                ...userBankAccount,
                bankAccountUserSurname: e.target.value,
              })
            }
            value={userBankAccount.bankAccountUserSurname || ""}
            fullWidth
          />
          <TextField
            size="small"
            label={texts.userBankName}
            onChange={(e) =>
              setUserBankAccount({
                ...userBankAccount,
                bankName: e.target.value,
              })
            }
            value={userBankAccount.bankName || ""}
            fullWidth
          />
        </>
      )}

      {/* PayPal bilgileri formu */}
      {paymentMethod === "paypal" && (
        <>
          <p>{texts.paypalDetailsDescription}</p>{" "}
          {/* PayPal bilgilerini almak için açıklama */}
          <TextField
            size="small"
            label={texts.userPaypalAccountOwnerName}
            onChange={(e) =>
              setUserBankAccount({
                ...userBankAccount,
                bankAccountUserName: e.target.value,
              })
            }
            value={userBankAccount.bankAccountUserName || ""}
            fullWidth
          />
          <TextField
            size="small"
            label={texts.userPaypalAccountOwnerSurname}
            onChange={(e) =>
              setUserBankAccount({
                ...userBankAccount,
                bankAccountUserSurname: e.target.value,
              })
            }
            value={userBankAccount.bankAccountUserSurname || ""}
            fullWidth
          />
          <TextField
            size="small"
            label={texts.paypalNumber}
            onChange={(e) =>
              setUserBankAccount({
                ...userBankAccount,
                paypalNumber: e.target.value,
              })
            }
            value={userBankAccount.paypalNumber || ""}
            fullWidth
          />
        </>
      )}
    </Box>
  );
};

export default RegisterStepFour;
