import React, { useEffect } from "react";
import { useLanguage } from "../../components/languageprovider/index";
import languages from "../../constants/language";
import { Spinner } from "../../components/Spinner";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    console.log(userAgent);
    // iOS cihazları kontrol et
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      console.log("iOS");
      // App Store linkine yönlendir
      window.location.href = "https://apps.apple.com/tr/app/a-flying/id6702013325";
    }
    // Android cihazları kontrol et
    else if (/android/i.test(userAgent)) {
      // Play Store linkine yönlendir
      console.log("Android");
      window.location.href = "https://play.google.com/store";
    } else if (/Mac OS/.test(userAgent)) {
      console.log("Mac OS");
      window.location.href = "https://apps.apple.com/tr/app/a-flying/id6702013325";
    }
  }, []);

  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    </>
  );
};

export default Index;
