import React, { useState, useEffect } from "react";
import { useLanguage } from "../../components/languageprovider";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Facebook,
  Instagram,
  WhatsApp,
  Share,
  Download,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import appScreenshot1 from "../../assets/images/iphone1.png"; // Uygulama ekran görüntüsü
import appScreenshot2 from "../../assets/images/iphone2.png"; // Uygulama ekran görüntüsü
import appScreenshot3 from "../../assets/images/iphone3.png"; // Uygulama ekran görüntüsü
import appScreenshot4 from "../../assets/images/iphone4.png"; // Uygulama ekran görüntüsü
import appLogo from "../../assets/images/logo.svg"; // Uygulama logosu
import language from "../../constants/language";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const texts = language[currentLanguage];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    try {
      setLoading(true);
      changeLanguage("de");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleShareMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShareMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loading ? (
        <div className="w-full flex items-center justify-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <div className="min-h-screen grid grid-rows-[1fr_auto] bg-gray-100 p-6">
          <Card
            className="shadow-2xl mx-auto p-6"
            style={{
              minWidth: "80vw",
              backgroundColor: "#ffffff",
              borderRadius: "16px",
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {/* Uygulama Hakkında Bilgi */}
              <div className="text-center mb-8">
                <img
                  src={appLogo}
                  alt="A-flying Logo"
                  className="w-24 h-24 mx-auto mb-4"
                />
                <Typography
                  variant="h4"
                  className="font-bold mb-4 text-gray-700"
                >
                  A-Flying
                </Typography>
                <Typography variant="body1" className="text-gray-600">
                  {texts.appDescription}
                </Typography>
              </div>
              {/* Butonlar */}

              <div className="flex justify-center gap-6 mb-8">
                {/* Uygulamayı İndir Butonu */}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Download />}
                  onClick={() => navigate("/link")}
                  className="px-6 py-3 text-lg"
                >
                  {texts.download}
                </Button>

                {/* Paylaş Butonu */}
                <div>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<Share />}
                    onClick={handleShareMenuOpen}
                    className="px-6 py-3 text-lg"
                  >
                    {texts.share}
                  </Button>

                  {/* Dropdown Menü */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleShareMenuClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleShareMenuClose();
                        window.open(
                          `https://wa.me/?text=A-flying ${texts.shareAppWithYourFriends} ${window.location.href}`,
                          "_blank"
                        );
                      }}
                    >
                      <WhatsApp className="mr-2" /> WhatsApp
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleShareMenuClose();
                        window.open("https://www.instagram.com", "_blank");
                      }}
                    >
                      <Instagram className="mr-2" /> Instagram
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleShareMenuClose();
                        window.open(
                          `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
                          "_blank"
                        );
                      }}
                    >
                      <Facebook className="mr-2" /> Facebook
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              {/* Görseller */}
              <div className="flex justify-center flex-wrap gap-2 mb-8">
                {[
                  appScreenshot1,
                  appScreenshot2,
                  appScreenshot3,
                  appScreenshot4,
                ].map((screenshot, index) => (
                  <div
                    key={index}
                    className="relative w-1/3 sm:w-1/6 bg-white rounded-2xl shadow-lg overflow-hidden"
                    style={{
                      padding: "10px",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  >
                    <div className="rounded-xl overflow-hidden">
                      <img
                        src={screenshot}
                        alt={`A-Flying Ekran Görüntüsü ${index + 1}`}
                        className="w-full h-auto object-cover rounded-xl"
                      />
                    </div>
                    <div
                      className="absolute inset-0 border-2 border-gray-200 rounded-2xl"
                      style={{
                        pointerEvents: "none",
                        boxShadow:
                          "0 8px 16px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.06)",
                      }}
                    />
                  </div>
                ))}
              </div>
            </motion.div>
          </Card>
        </div>
      )}
    </>
  );
};

export default Index;
