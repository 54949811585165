import { LanguageProvider } from "../components/languageprovider";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import routes from "../routes/route";

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="w-full flex flex-col justify-start ">
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
