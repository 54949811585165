import "./App.css";
import Main from "./pages/main";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="w-full h-screen font-[Inter] flex justify-start items-start ">
      <ToastContainer />
      <Main />
    </div>
  );
}

export default App;
