import React, { useState } from "react";
import { useLanguage } from "../../components/languageprovider/index";
import languages from "../../constants/language";
import { Spinner } from "../../components/Spinner";
import { motion } from "framer-motion";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];

  const countries = [
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
  ];

  // State for the support form
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate form submission
    await new Promise((resolve) => setTimeout(resolve, 2000));

    console.log("Email:", email);
    console.log("Message:", message);
    toast.success(texts.messageSent);
    setEmail("");
    setMessage("");
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen">
        <ToastContainer />
        {/* Country Flags Section */}
        <motion.div
          className="flex gap-4 items-center justify-center mb-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          {countries.map((country, index) => (
            <button
              key={index}
              onClick={() => {
                changeLanguage(country.id);
              }}
            >
              <img
                className="w-16 rounded-lg cursor-pointer hover:scale-110 transition ease 1s"
                src={country.flags.svg}
                alt={country.name}
              />
            </button>
          ))}
        </motion.div>

        {/* Support Form */}
        <motion.div
          className="flex flex-col items-center gap-4 w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <Typography variant="body1" gutterBottom>
            {texts.supportFormDescription}
          </Typography>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-2 w-full max-w-md"
          >
            <TextField
              label={texts.email}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
              fullWidth
            />
            <TextField
              label={texts.message}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={20} /> : texts.send}
            </Button>
          </form>
        </motion.div>
      </div>
    </>
  );
};

export default Index;
