const ibanData = {
  tr: { length: 26, regex: /^TR\d{24}$/ }, // Türkiye
  de: { length: 22, regex: /^DE\d{20}$/ }, // Almanya
  en: { length: 22, regex: /^GB\d{20}$/ }, // Birleşik Krallık
  fr: { length: 27, regex: /^FR\d{25}$/ }, // Fransa
  es: { length: 24, regex: /^ES\d{22}$/ }, // İspanya
  it: { length: 27, regex: /^IT\d{25}$/ }, // İtalya
  nl: { length: 18, regex: /^NL\d{16}$/ }, // Hollanda
  ch: { length: 21, regex: /^CH\d{19}$/ }, // İsviçre
  be: { length: 16, regex: /^BE\d{14}$/ }, // Belçika
  at: { length: 20, regex: /^AT\d{18}$/ }, // Avusturya
  se: { length: 24, regex: /^SE\d{22}$/ }, // İsveç
  pl: { length: 28, regex: /^PL\d{26}$/ }, // Polonya
  pt: { length: 25, regex: /^PT\d{23}$/ }, // Portekiz
  ro: { length: 24, regex: /^RO\d{22}$/ }, // Romanya
  bg: { length: 22, regex: /^BG\d{20}$/ }, // Bulgaristan
  hu: { length: 28, regex: /^HU\d{26}$/ }, // Macaristan
  cz: { length: 24, regex: /^CZ\d{22}$/ }, // Çek Cumhuriyeti
  sk: { length: 24, regex: /^SK\d{22}$/ }, // Slovakya
  si: { length: 19, regex: /^SI\d{17}$/ }, // Slovenya
  hr: { length: 21, regex: /^HR\d{19}$/ }, // Hırvatistan
  cy: { length: 28, regex: /^CY\d{26}$/ }, // Kıbrıs
  gr: { length: 27, regex: /^GR\d{25}$/ }, // Yunanistan
  fi: { length: 18, regex: /^FI\d{16}$/ }, // Finlandiya
  dk: { length: 18, regex: /^DK\d{16}$/ }, // Danimarka
  ee: { length: 20, regex: /^EE\d{18}$/ }, // Estonya
  ie: { length: 22, regex: /^IE\d{20}$/ }, // İrlanda
  lv: { length: 21, regex: /^LV\d{19}$/ }, // Letonya
  lt: { length: 20, regex: /^LT\d{18}$/ }, // Litvanya
  lu: { length: 20, regex: /^LU\d{18}$/ }, // Lüksemburg
  mt: { length: 31, regex: /^MT\d{29}$/ }, // Malta

  // Büyük ülkelerden bazıları:
  us: { length: 0, regex: null }, // ABD (IBAN kullanmıyor, boş bırakıldı)
  ca: { length: 0, regex: null }, // Kanada (IBAN kullanmıyor)
  au: { length: 0, regex: null }, // Avustralya (IBAN kullanmıyor)
  ru: { length: 0, regex: null }, // Rusya (IBAN kullanmıyor)

  // Diğer büyük ülkelerden bazıları:
  br: { length: 29, regex: /^BR\d{27}[A-Z]{1}[A-Z0-9]{1}$/ }, // Brezilya
  in: { length: 34, regex: /^IN\d{32}$/ }, // Hindistan (IBAN kullanmıyor, örnek eklenebilir)
  cn: { length: 0, regex: null }, // Çin (IBAN kullanmıyor)
  jp: { length: 0, regex: null }, // Japonya (IBAN kullanmıyor)
  za: { length: 0, regex: null }, // Güney Afrika (IBAN kullanmıyor)
  sa: { length: 24, regex: /^SA\d{22}$/ }, // Suudi Arabistan
  ae: { length: 23, regex: /^AE\d{21}$/ }, // Birleşik Arap Emirlikleri
};

export default ibanData;
