const countries = [
  {
    id: "de",
    name: "Deutschland", // Almanya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
    },
  },
  {
    id: "tr",
    name: "Türkiye", // Türkiye
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
    },
  },
  {
    id: "en",
    name: "United Kingdom", // Birleşik Krallık
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
    },
  },
  {
    id: "fr",
    name: "France", // Fransa
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/fr.svg",
    },
  },
  {
    id: "it",
    name: "Italia", // İtalya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/it.svg",
    },
  },
  {
    id: "nl",
    name: "Nederland", // Hollanda
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/nl.svg",
    },
  },
  {
    id: "es",
    name: "España", // İspanya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/es.svg",
    },
  },
  {
    id: "pt",
    name: "Portugal", // Portekiz
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/pt.svg",
    },
  },
  {
    id: "se",
    name: "Sverige", // İsveç
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/se.svg",
    },
  },
  {
    id: "no",
    name: "Norge", // Norveç
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/no.svg",
    },
  },
  {
    id: "fi",
    name: "Suomi", // Finlandiya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/fi.svg",
    },
  },
  {
    id: "dk",
    name: "Danmark", // Danimarka
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/dk.svg",
    },
  },
  {
    id: "pl",
    name: "Polska", // Polonya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/pl.svg",
    },
  },
  {
    id: "cz",
    name: "Česká republika", // Çek Cumhuriyeti
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/cz.svg",
    },
  },
  {
    id: "hu",
    name: "Magyarország", // Macaristan
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/hu.svg",
    },
  },
  {
    id: "ro",
    name: "România", // Romanya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/ro.svg",
    },
  },
  {
    id: "bg",
    name: "България", // Bulgaristan
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/bg.svg",
    },
  },
  {
    id: "sk",
    name: "Slovensko", // Slovakya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/sk.svg",
    },
  },
  {
    id: "si",
    name: "Slovenija", // Slovenya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/si.svg",
    },
  },
  {
    id: "hr",
    name: "Hrvatska", // Hırvatistan
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/hr.svg",
    },
  },
  {
    id: "lt",
    name: "Lietuva", // Litvanya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/lt.svg",
    },
  },
  {
    id: "lv",
    name: "Latvija", // Letonya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/lv.svg",
    },
  },
  {
    id: "ee",
    name: "Eesti", // Estonya
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/ee.svg",
    },
  },
];

const countryOptions = countries.map(({ name, flags, id }) => ({
  value: id,
  label: (
    <div className="flex items-center gap-2">
      <img
        src={flags.svg}
        alt={name}
        className="h-5 w-5 rounded-full object-cover"
      />
      {name}
    </div>
  ),
}));

export { countries, countryOptions };
