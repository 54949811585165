import { motion, useAnimation } from "framer-motion";
import React from "react";
import BackgroundImage from "../../assets/images/bg.png";
import LogoImage from "../../assets/images/backgroundLogo.svg";
import AppStoreBadge from "../../assets/images/appStore.svg";
import PlayStoreBadge from "../../assets/images/playStore.svg";
import { useLanguage } from "../../components/languageprovider/index";
import languages from "../../constants/language";

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const lang = currentLanguage;
  const texts = languages[lang];

  const controls = useAnimation();
  React.useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const countries = [
    {
      id: "de",
      name: "German",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
      },
    },
    {
      id: "tr",
      name: "Turkish",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
      },
    },
    {
      id: "en",
      name: "English",
      flags: {
        svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
      },
    },
  ];

  return (
    <motion.div
      className="relative h-screen"
      animate={controls}
      initial={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={BackgroundImage}
          alt="Background"
        />
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-center gap-6 px-4">
        <motion.div
          className="flex flex-wrap gap-4 items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          {countries.map((country, index) => (
            <button
              key={index}
              onClick={() => {
                changeLanguage(country.id);
              }}
            >
              <img
                className="w-16 rounded-lg cursor-pointer hover:scale-110 transition ease 1s"
                src={country.flags.svg}
                alt={country.name}
              />
            </button>
          ))}
        </motion.div>
        <motion.div
          className="flex flex-wrap gap-4 mt-8 items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <button
            onClick={() =>
              window.open("https://youtu.be/VkBpa6rIQec", "_blank")
            }
            className="bg-orange-400 px-4 py-2 rounded-full flex items-center justify-center hover:scale-110 transition-transform duration-300"
          >
            <img
              alt="YouTube"
              className="w-8"
              src="https://img.icons8.com/color/48/000000/youtube-play.png"
            />
            <img src={countries[0].flags.svg} alt="flag" className="w-8" />
          </button>
          <button
            onClick={() =>
              window.open("https://youtu.be/VkBpa6rIQec", "_blank")
            }
            className="bg-blue-400 px-4 py-2 rounded-full flex items-center justify-center hover:scale-110 transition-transform duration-300"
          >
            <img
              className="w-8"
              alt="YouTube"
              src="https://img.icons8.com/color/48/000000/youtube-play.png"
            />
            <img src={countries[1].flags.svg} alt="flag" className="w-8" />
          </button>

          <button
            onClick={() =>
              window.open("https://youtu.be/VkBpa6rIQec", "_blank")
            }
            className="bg-red-400 px-4 py-2 rounded-full flex items-center justify-center hover:scale-110 transition-transform duration-300"
          >
            <img
              alt="YouTube"
              className="w-8"
              src="https://img.icons8.com/color/48/000000/youtube-play.png"
            />
            <img src={countries[2].flags.svg} alt="flag" className="w-8" />
          </button>
        </motion.div>
        <motion.div
          className="flex flex-col items-center gap-2"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <img
            className="md:w-1/2 w-full hover:scale-110 transition ease 1s"
            src={LogoImage}
            alt="Logo"
          />
          <span className="font-[Inter] font-bold text-4xl text-white hover:scale-110 transition ease 1s">
            {texts.appTitle}
          </span>
          <span className="font-[Inter] text-xl text-white hover:scale-110 transition ease 1s">
            {texts.appDescription}
          </span>
        </motion.div>

        <motion.div
          className="flex flex-wrap gap-4 mt-8 items-center justify-center w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <button
            onClick={() => {
              window.open("https://a-flying.com/link", "_blank");
            }}
            className="bg-white px-8 py-2 gap-2 rounded-xl flex flex-row items-center justify-center cursor-pointer scale-90 hover:scale-100 transition ease 1s 
      lg:w-1/3 md:w-1/3 w-1/2"
          >
            <img
              className="w-6 lg:w-16 md:w-12 sm:w-6" // Resmin genişliği ekran boyutuna göre değişir
              src={AppStoreBadge}
              alt="App Store"
            />
            <span className="text-black font-bold text-xs md:text-base lg:text-xl">
              {texts.downloadFromAppStore}
            </span>
          </button>

          <button
            onClick={() => {
              window.open("https://a-flying.com/link", "_blank");
            }}
            className="bg-white px-8 py-2 gap-2 rounded-xl flex flex-row items-center justify-center cursor-pointer scale-90 hover:scale-100 transition ease 1s
      lg:w-1/3 md:w-1/3 w-1/2"
          >
            <img
              className="w-6 lg:w-16 md:w-12 sm:w-6" // Resmin genişliği ekran boyutuna göre değişir
              src={PlayStoreBadge}
              alt="Play Store"
            />
            <span className="text-black font-bold text-xs md:text-base lg:text-xl">
              {texts.downloadFromPlayStore}
            </span>
          </button>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Index;
