import React from "react";
import { Box, Button, Typography } from "@mui/material";

const RegisterStepOne = ({
  imageData,
  fileInputRef,
  triggerFileInput,
  handleFileUpload,
  texts,
}) => {
  return (
    <Box className="flex flex-col items-center gap-4">
      <Typography variant="h6" className="text-center">
        {texts.uploadLogo} {/* Dil seçimine göre açıklama */}
      </Typography>

      <div className="relative flex justify-center mb-4">
        <img
          className="h-[300px] w-[300px] rounded-xl object-cover object-center shadow-xl"
          src={imageData ? imageData : "https://placehold.co/300x300"} // Görsel yüklenmemişse placeholder
          alt="logo"
        />
      </div>

      <Button
        sx={{
          width: "300px",
          borderRadius: "8px",
          opacity: 0.9,
          "&:hover": { opacity: 1 }, // Hover efekti
        }}
        variant="contained"
        color="primary"
        onClick={triggerFileInput}
      >
        <input
          type="file"
          id="file-upload"
          accept="image/jpeg, image/png, image/jpg, image/heic"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }} // Input görünmez
        />
        {texts.addImage}
      </Button>

      <Typography variant="body2" className="text-center mt-2">
        {texts.imageDescription} {/* Görsel hakkında açıklama */}
      </Typography>
    </Box>
  );
};

export default RegisterStepOne;