import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { API_URL, API_PORT } from "../../constants/appConfig";

const RegisterStepTwo = ({ user, setUser, texts }) => {
  const [errors, setErrors] = useState({
    userUsername: "",
    userEmail: "",
    phoneNumber: "",
    taxNumber: "",
  });

  const handleValidation = async (field, value) => {
    let endpoint = "";
    let fieldName = "";

    switch (field) {
      case "userUsername":
        endpoint = "checkUsername";
        fieldName = "userName";
        break;
      case "userEmail":
        endpoint = "checkEmail";
        fieldName = "userEmail";
        break;
      case "phoneNumber":
        endpoint = "checkPhone";
        fieldName = "phoneNumber";
        break;
      case "taxNumber":
        endpoint = "checkTaxNumber";
        fieldName = "taxNumber";
        break;
      default:
        return;
    }

    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/${endpoint}?${fieldName}=${encodeURIComponent(
          value
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      data
        ? setErrors({ ...errors, [field]: true })
        : setErrors({ ...errors, [field]: false });
    } catch (error) {
      console.error("Hata oluştu:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h6" gutterBottom>
        {texts.personalInfo} {/* Genel başlık, dil seçimine göre değişir */}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {texts.infoDescription} {/* Formun üstünde açıklama */}
      </Typography>

      <TextField
        size="small"
        label={texts.name}
        onChange={(e) => setUser({ ...user, userName: e.target.value })}
        value={user.userName || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.nameDescription} {/* İsim açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.surname}
        onChange={(e) => setUser({ ...user, userSurname: e.target.value })}
        value={user.userSurname || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.surnameDescription} {/* Soyisim açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.userName}
        value={user.userUsername || ""}
        error={errors.userUsername ? true : false}
        helperText={errors.userUsername ? texts.userNameAlreadyExists : ""}
        fullWidth
        onChange={(e) => {
          setUser({ ...user, userUsername: e.target.value });
          handleValidation("userUsername", e.target.value);
        }}
      />
      <Typography variant="body2" color="textSecondary">
        {texts.usernameDescription} {/* Kullanıcı adı açıklaması */}
      </Typography>
      {/* Vergi Numarası */}
      <TextField
        size="small"
        label={texts.taxNumber}
        value={user.userTaxNumber || ""}
        error={errors.taxNumber}
        helperText={errors.taxNumber ? texts.taxNumberAlreadyExists : ""}
        fullWidth
        type="number"
        onChange={(e) => {
          setUser({ ...user, userTaxNumber: e.target.value });
          handleValidation("taxNumber", e.target.value);
        }}
      />
      <Typography variant="body2" color="textSecondary">
        {texts.taxNumberDescription} {/* Vergi numarası açıklaması */}
      </Typography>

      {/* Telefon */}
      <TextField
        size="small"
        label={texts.phoneNumber}
        value={user.userPhone || ""}
        error={errors.phoneNumber ? true : false}
        helperText={errors.phoneNumber ? texts.phoneNumberAlreadyExists : ""}
        fullWidth
        type="number"
        onChange={(e) => {
          setUser({ ...user, userPhone: e.target.value });
          handleValidation("phoneNumber", e.target.value);
        }}
      />
      <Typography variant="body2" color="textSecondary">
        {texts.phoneDescription} {/* Telefon numarası açıklaması */}
      </Typography>

      {/* E-posta */}
      <TextField
        size="small"
        label={texts.email}
        value={user.userEmail || ""}
        error={errors.userEmail ? true : false}
        helperText={errors.userEmail ? texts.emailAdressAlreadyExists : ""}
        fullWidth
        onChange={(e) => {
          setUser({ ...user, userEmail: e.target.value });
          handleValidation("userEmail", e.target.value);
        }}
      />
      <Typography variant="body2" color="textSecondary">
        {texts.emailDescription} {/* E-posta açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.password}
        onChange={(e) => setUser({ ...user, userPassword: e.target.value })}
        value={user.userPassword || ""}
        fullWidth
        type="password"
      />
      <Typography variant="body2" color="textSecondary">
        {texts.passwordDescription} {/* Şifre açıklaması */}
      </Typography>

      <TextField
        size="small"
        label={texts.companyName}
        onChange={(e) => setUser({ ...user, userFirmName: e.target.value })}
        value={user.userFirmName || ""}
        fullWidth
      />
      <Typography variant="body2" color="textSecondary">
        {texts.companyDescription} {/* Şirket adı açıklaması */}
      </Typography>
    </Box>
  );
};

export default RegisterStepTwo;
